/* !
 * index.js v1.0.0
 * Copyright, Connecty Inc.
 */
((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	const slider = () => {
		if(document.querySelectorAll('.top-slider01').length) {
			document.querySelectorAll('.top-slider01-item').forEach(ele => {
				ele.style.height = window.innerHeight + 'px';
				console.log(ele.style.height);
			});
			const topslider01 = new Swiper('.top-slider01', {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				loopAdditionalSlides: 0,
				speed: 1000,
				autoplay: {
					delay: 4000,
					disableOnInteraction: false,
				},
				pagination: {
					el: '.top-slider01-control .swiper-pagination',
					clickable: true,
				}
			});
			document.querySelector('.top-slider01-control-button').addEventListener('click', (e) => {
				if(!(e.currentTarget.classList.contains(a))) {
					e.currentTarget.ariaLabel = '停止';
					e.currentTarget.classList.add(a);
					topslider01.autoplay.start();
				} else {
					e.currentTarget.ariaLabel = '再生';
					e.currentTarget.classList.remove(a);
					topslider01.autoplay.stop();
				}
			});
		}
		if(document.querySelectorAll('.top-slider02').length) {
			new Swiper('.top-slider02', {
				slidesPerView: 1,
				spaceBetween: 0,
				loop: true,
				loopAdditionalSlides: 1,
				speed: 1000,
				autoplay: false,
				// pagination: {
				// 	el: '.swiper-pagination',
				// 	clickable: true,
				// },
				navigation: {
					nextEl: '.top-slider02-control .swiper-button-next',
					prevEl: '.top-slider02-control .swiper-button-prev',
				},
			});
		}
		if(document.querySelectorAll('.top-slider04').length) {
			new Swiper('.top-slider04', {
				slidesPerView: 1,
				spaceBetween: 0,
				// loop: true,
				// loopAdditionalSlides: 1,
				speed: 1000,
				autoplay: false,
				watchSlidesProgress: true,
				navigation: {
					nextEl: '.top-slider04-control .swiper-button-next',
					prevEl: '.top-slider04-control .swiper-button-prev',
				},
				breakpoints: {
				  1024: {
					slidesPerView: 3,
					spaceBetween: 10,
				  },
				},
			});
		}
	};

	slider();


})(window.FUNCTIONS);
